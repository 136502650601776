@import 'variables';

* {
    font-family: 'PT Sans', sans-serif;
}

// h1,
// h2,
// h3,
// h4 {
//     margin: 0px;
// }

h1 {
    font-family: 'PT Sans', sans-serif;
    font-weight: bold;
    font-size: 50px;
    color: $darkgreen;
    line-height: 57px;
}

h2 {
    font-family: 'PT Sans', sans-serif;
    font-weight: bold;
    font-size: 35px;
    color: $darkgreen;
    line-height: 40px;
}

h3 {
    font-family: 'PT Sans', sans-serif;
    font-weight: bold;
    font-size: 24px;
    color: $darkgreen;
    line-height: 32px;
}

h4 {
    font-family: 'PT Sans', sans-serif;
    font-weight: bold;
    font-size: 18px;
    color: $darkgreen;
    line-height: 29px;
}

h5 {
    font-weight: bold;
    font-style: italic;
    font-size: 18px;
    margin-top: 0;
    margin-bottom: 0;
    color: #000000;
}

h6 {
    font-weight: normal;
    font-style: italic;
    font-size: 25px;
    margin-top: 0;
    color: #000000;
    margin-bottom: 1em !important;
}

.introttext {
    font-family: 'PT Sans', sans-serif;
    font-style: italic;
    font-size: 25px;
    color: $black;
    line-height: 37px;
}

p {
    font-family: 'PT Sans', sans-serif;
    font-size: 18px;
    color: $black;
    line-height: 29px;
    margin-top: 0;
    margin-bottom: 2em !important;
}

a {
    font-family: 'PT Sans', sans-serif;
    font-style: italic;
    font-size: 18px;
    color: $darkgreen;
    line-height: 29px;
}

.tags {
    font-family: 'PT Sans', sans-serif;
    font-size: 16px;
    color: $black;
}

.bu {
    font-family: 'PT Sans', sans-serif;
    font-size: 14px;
    color: $black;
    line-height: 18px;
}


.vc_btn3,
button {
    font-size: 16px !important;
    font-weight: bold !important;
    color: #ffffff !important;
    background: $green !important;
    padding: 13px 58px !important;
    text-transform: uppercase !important;
    border-radius: 8px !important;
    font-style: normal !important;
    text-decoration: none !important;

    i {
        right: 45px !important;
    }

    &:after {
    }

    &:hover {
        border: 2px solid $green !important;
        background: #ffffff !important;
        color: $green !important;
    }
}